<template>
  <div>
    <NuxtRouteAnnouncer />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>
// Adicionando o meta para verificação de domínio do Facebook
useHead({
  meta: [
    {
      name: 'facebook-domain-verification',
      content: 'hii7b1g3wd63nauhgwna14yo4k63bf'
    }
  ]
});

onMounted(() => {
  // Global site tag (gtag.js) - Google Analytics
  // const script = document.createElement('script');
  // script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-119536193-2';
  // script.async = true;
  // document.head.appendChild(script);

  // window.dataLayer = window.dataLayer || [];
  // function gtag() { dataLayer.push(arguments); }
  // gtag('js', new Date());
  // gtag('config', 'UA-119536193-2');

  // Facebook Pixel Code
  // !(function (f, b, e, v, n, t, s) {
  //   if (f.fbq) return; n = f.fbq = function () {
  //     n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
  //   }
  //   if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
  //   n.queue = []; t = b.createElement(e); t.async = !0;
  //   t.src = v; s = b.getElementsByTagName(e)[0];
  //   s.parentNode.insertBefore(t, s);
  // }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js'));
  // fbq('init', '1664626107020268');
  // fbq('track', 'PageView');
});
</script>
